<!--
 * @Description: 房源订单结算数据
 * @Author: 琢磨先生
 * @Date: 2022-08-09 02:22:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-09 05:39:19
-->

<template>
    <el-card class="box">
        <el-form :model="query" ref="query" :inline="true">
            <el-form-item label="订单号">
                <el-input v-model="query.q" placeholder="请输入订单号"></el-input>
            </el-form-item>
            <el-form-item label="业主名称">
                <el-input v-model="query.consignee" placeholder="收件人姓名"></el-input>
            </el-form-item>
            <el-form-item label="下单日期">
                <el-date-picker v-model="query.date_list" type="daterange" start-placeholder="开始日期"
                    end-placeholder="结束日期" />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
            </el-form-item>
        </el-form>
    </el-card>
    <el-card class="box data" v-loading="loading">
        <el-table :data="tableData.data">
            <el-table-column label="编号" prop="id" width="80"></el-table-column>
            <el-table-column label="房源LOGO" width="120">
                <template #default="scope">
                    <el-image fit="cover" :src="scope.row.order_store.house_logo_url" style="width:80px;height:80px;">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="房间" width="300">
                <template #default="scope">
                    <div>{{ scope.row.order_store.house_name }}</div>
                    <div>房号：{{ scope.row.order_store.house_no }}</div>
                </template>
            </el-table-column>
            <el-table-column label="结算总额" width="100">
                <template #default="scope">
                    ￥{{ scope.row.decimal_order_amount }}
                </template>
            </el-table-column>
            <el-table-column label="规则比例" width="140">
                <template #default="scope">
                    <div>{{ scope.row.income_rule_name }}</div>
                    <div>{{ scope.row.decimal_income_rate }}%</div>
                </template>
            </el-table-column>
            <el-table-column label="业主收益" width="100">
                <template #default="scope">
                    ￥{{ scope.row.decimal_amount }}
                </template>
            </el-table-column>
            <el-table-column label="业主" width="120">
                <template #default="scope">
                    <div>
                        {{ scope.row.landlord.name }}
                    </div>
                    <div>
                        {{ scope.row.landlord.mobile_phone }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="140">
                <template #default="scope">
                    <el-tag type="info" v-if="scope.row.status == 0" size="small">{{ scope.row.status_text }}</el-tag>
                    <el-tag type="success" v-else-if="scope.row.status == 5" size="small">{{ scope.row.status_text }}
                    </el-tag>
                    <el-tag type="danger" v-else size="small">{{ scope.row.status_text }}
                    </el-tag>
                    <div>
                       入账：{{scope.row.receive_status_text}}
                    </div>
                </template>

            </el-table-column>
            <el-table-column label="订单房费" width="160">
                <template #default="scope">
                    <div>房费：￥{{ scope.row.order_store.decimal_amount }}</div>
                    <div>违约金：￥{{ scope.row.order_store.decimal_break_amount }}</div>
                </template>

            </el-table-column>
            <el-table-column label="入离日期" width="160">
                <template #default="scope">
                    <div>入：{{ scope.row.order_store.checkin_date }}</div>
                    <div>离：{{ scope.row.order_store.checkout_date }}</div>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="280"></el-table-column>
            <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
            <el-table-column label="操作" fixed="right" width="140">
                <template #default="scope">
                    <settle-check :model="scope.row" @change="onSearch"></settle-check>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="tableData.counts > 0" @size-change="pageSizeChange" @current-change="pageCurrentChange"
            :current-page="query.pageIndex" :page-sizes="[20, 40, 60, 80, 100]" :page-size="query.pageSize"
            layout="total, sizes, prev, pager, next" :total="tableData.counts">
        </el-pagination>
    </el-card>
</template>

<script>
import SettleCheck from './settle_order_check.vue'
export default {
    components: {
        SettleCheck
    },
    data() {
        return {
            loading: false,
            //数据
            tableData: {
                counts: 0
            },
            query: {
                pageIndex: 1,
                pageSize: 20,
                order: {
                    sort: "",
                    fieldName: ""
                },
                q: ""
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        /**
     * 搜索
     */
        onSearch() {
            this.query.pageIndex = 1;
            this.loadData();
        },
        /**
         * 
         */
        loadData() {
            this.loading = true;
            this.$http.post("seller/v1/settle", this.query).then((res) => {
                this.loading = false;
                if (res.code == 0) {
                    this.tableData = res.data;
                }
            });
        },
        /**
     * 排序
     */
        onSort(e) {
            if (e.prop) {
                this.query.order.sort = e.order;
                this.query.order.fieldName = e.prop;
                this.loadData();
            }
        },
        /**
         * 分页页数更改
         */
        pageSizeChange(val) {
            this.query.pageSize = val;
            this.loadData();
        },
        /**
         * 分页页码更改
         */
        pageCurrentChange(val) {
            this.query.pageIndex = val;
            this.loadData();
        },
    }
}
</script>

<style   scoped>
</style>