<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-08-09 04:28:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-09 05:34:55
-->

<template>
    <el-button type="primary" link v-if="model.status == 0" @click="onOpenDialog">审核</el-button>
    <el-dialog title="房费结算审核" v-model="visibleDialog" width="800px" :append-to-body="true" :close-on-click-modal="false"
        :close-on-press-escape="false" :destroy-on-close="true">
        <el-form :model="form" ref="form" :rules="rules" label-width="140px">
            <el-form-item label="结果" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio :label="5">通过</el-radio>
                    <el-radio :label="10">无效</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="备注信息" style="margin-top: 20px">
                <el-input v-model="form.check_remark" type="textarea" rows="4" placeholder="请填写备注信息"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
        </template>
    </el-dialog>

</template>

<script>
export default {
    data() {
        return {
            visibleDialog: false,
            saving: false,
            form: {

            },
            rules: {
                status: [
                    { required: true, message: '请选择', trigger: 'blur' }
                ]
            }
        }
    },
    props: ['model'],
    emits: ['change'],
    methods: {
        /**
         *  打开
         */
        onOpenDialog() {
            this.visibleDialog = true;
        },

        /**
         * 
         */
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.saving = true;
                    this.form.id = this.model.id;
                    this.form.landlord_version = this.model.landlord.version;
                    this.$http.post('seller/v1/settle/check', this.form).then(res => {
                        if (res.code == 0) {
                            this.$emit('change');
                            this.visibleDialog = false;
                        }
                    });
                }
            })
        }
    }
}
</script>

<style  scoped>
</style>